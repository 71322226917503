<template>
  <v-progress-linear
    v-if="pending"
    indeterminate
    color="primary lighten-3"
    height="3"
    absolute
    top
  />
</template>

<script>
  import { mapGetters } from "vuex";
  
  export default {
    computed: {
      ...mapGetters({
        pending: "getPending",
      }),
    },
  }
</script>

 



