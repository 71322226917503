<template>
  <svg :viewBox="viewBox" :width="width" :height="height">
    <g class="x-axis-bottom" :transform="xAxisBottomTranslate"></g>
    <g class="x-axis-top" :transform="xAxisTopTranslate"></g>
    <g class="y-axis-left" :transform="yAxisLeftTranslate"></g>
    <g class="y-axis-right" :transform="yAxisRightTranslate"></g>
    <g class="content" :transform="contentTranslate"></g>
    <slot></slot>
  </svg>
</template>

<script>
import d3SizeMixin from './d3SizeMixin'

export default {
  mixins: [d3SizeMixin],
  props: {
    width: {
      type: Number,
      default: 950,
    },
    height: {
      type: Number,
      default: 600,
    },
    margin: {
      type: Object,
      default: () => ({
        left: 30,
        top: 30,
        right: 30,
        bottom: 30,
      }),
    },
  },
}
</script>

<style scoped></style>
