export default {
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`
    },
    contentTranslate() {
      return `translate(${this.margin.left}, ${this.margin.top})`
    },
    xAxisTopTranslate() {
      return `translate(${this.margin.left}, ${this.margin.top})`
    },
    xAxisBottomTranslate() {
      return `translate(${this.margin.left}, ${this.height - this.margin.bottom})`
    },
    yAxisLeftTranslate() {
      return `translate(${this.margin.left}, ${this.margin.top})`
    },
    yAxisRightTranslate() {
      return `translate(${this.width - this.margin.right}, ${this.margin.top})`
    },
  },
}
