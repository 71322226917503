<template>
  <v-app-bar app color="lightgrey" flat clipped-left>
    <LoadBar />
    <!-- без явного задания ml-n4 левый маргин пропадает при включении полосы загрузки -->
    <v-toolbar-title style="width: 200px"
      ><router-link to="/" custom v-slot="{ navigate }">
        <span @click="navigate" @keypress.enter="navigate" role="link" style="cursor: pointer"
          >Потерянные годы</span
        >
      </router-link></v-toolbar-title
    >
    <v-spacer></v-spacer>
    <v-tabs class="py-0 px-8 ml-n4 fill-height" right optional>
      <v-tab v-for="page in pagesDescription" :key="page.displayName" :to="page.path">
        {{ page.displayName }}
      </v-tab>
      <!-- <div class="d-flex align-center">
        <ExitButtonAndDialog />
      </div> -->
    </v-tabs>
  </v-app-bar>
</template>

<script>
// import ExitButtonAndDialog from './ExitButtonAndDialog'
import LoadBar from './LoadBar'

export default {
  components: {
    //    ExitButtonAndDialog,
    LoadBar,
  },
  data: () => ({
    isExitDialogOpen: false,
    pagesDescription: [
      {
        displayName: 'Субъекты РФ',
        path: '/regions',
      },
      {
        displayName: 'Причины смерти',
        path: '/reasons',
      },
      {
        displayName: 'Возрастные группы',
        path: '/by-age',
      },
      {
        displayName: 'Динамика',
        path: '/dynamic',
      },
      // {
      //   displayName: 'Оперативные показатели',
      //   path: '/operational-indicators',
      // },
    ],
  }),
}
</script>
