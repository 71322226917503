import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","color":"lightgrey","flat":"","clipped-left":""}},[_c('LoadBar'),_c(VToolbarTitle,{staticStyle:{"width":"200px"}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Потерянные годы")])]}}])})],1),_c(VSpacer),_c(VTabs,{staticClass:"py-0 px-8 ml-n4 fill-height",attrs:{"right":"","optional":""}},_vm._l((_vm.pagesDescription),function(page){return _c(VTab,{key:page.displayName,attrs:{"to":page.path}},[_vm._v(" "+_vm._s(page.displayName)+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }