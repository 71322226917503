import { select } from 'd3'

export default {
  computed: {
    svg() {
      return select(this.$el).select("svg");
    },
    mainPlotAreaHeight() {
      return this.height - this.margin.top - this.margin.bottom;
    },
    mainPlotAreaWidth() {
      return this.width - this.margin.left - this.margin.right;
    },
    animationDuration() {
      return this.options.animationDuration ? this.options.animationDuration : 500;
    },
  },
  methods: {
    changeOpacity(element, opacity) {
      element
        .transition("changeOpacity")
        .duration(this.animationDuration)
        .attr("fill-opacity", opacity);
    },
    bindEvents(element, events) {
      if (typeof events !== "object") return;
      Object.keys(events).forEach((e) => {
        element.on(e, events[e]);
      });
    },
  }
}